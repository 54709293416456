.mi-media {
  margin-top: 10px;

  &-wrapper {
    border-left: 3px solid $color-border;
  }

  & {
    display: flex;
  }

  &-summary {
    align-self: flex-start;
    flex: 0 0 80px;
    max-width: 80px;
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -9px;
      top: 6px;
      height: 15px;
      width: 15px;
      border-radius: 100px;
      background: $color-bg-body;
      border: 3px solid $color-border;
    }
  }

  &-year {
    color: $color-body;
    margin-bottom: 0;
  }

  &-details {
    position: relative;
    padding-left: 50px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 15px;
      height: 1px;
      width: 30px;
      background: $color-border;
    }

    h2 {
      color: $color-theme;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 17px !important;
      line-height: 2.14rem;

      a span {
        padding: 0 10px;
        color: #33af8d;
      }

      @media #{$layout-mobile-lg} {
        line-height: 2.04rem;
      }
    }

    p {
      margin-bottom: 0;

      &.venue {
        font-style: italic;
        font-size: 12px;
      }

      &.projects {
        font-size: 12px;

        a {
          border: 1px solid $color-theme;
          border-radius: 4px;
          padding: 2px 5px;
          margin: 0 5px;

          &:hover {
            border-color: white;
            color: white;
          }
        }
      }
    }
  }

  @media #{$layout-notebook} {
    &-summary {
      flex: 0 0 170px;
      max-width: 170px;
    }
  }

  @media #{$layout-tablet} {
    &-summary {
      flex: 0 0 170px;
      max-width: 170px;
    }
  }

  @media #{$layout-mobile-lg} {
    flex-wrap: wrap;

    &-details {
      padding-left: 20px;

      &::before {
        left: 3px;
        top: -15px;
        width: 12px;
      }
    }
  }
}
