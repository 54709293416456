.mi-home {
  &-area {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 1;
  }

  &-particle {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &-content {
    text-align: center;
    padding: 50px 80px;

    h1 {
      font-weight: 700;
    }

    p {
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 2rem;
    }

    .mi-socialicons {
      margin-top: 20px;
    }

    .upcoming-travel {
      font-size: 15px;
      margin-top: 75px;

      .title {
        font-weight: bold;
        color: $color-theme;
      }

      .event-name {
        font-weight: bold;

        a {
          color: white;
        }
      }
    }
  }

  @media #{$layout-laptop} {
    &-content {
      padding: 50px 0px;
    }
  }

  @media #{$layout-notebook} {
    &-content {
      padding: 50px 0px;
    }
  }

  @media #{$layout-tablet} {
    &-content {
      padding: 50px 0px;

      p {
        font-size: 1.15rem;
        line-height: 1.75rem;
      }
    }
  }

  @media #{$layout-mobile-lg} {
    &-content {
      padding: 50px 0px;

      h1 {
        font-size: 35px;
      }

      p {
        font-size: 1.08rem;
        line-height: 1.55rem;
      }
    }
  }

  @media #{$layout-mobile-sm} {
    &-content {
      h1 {
        span {
          display: inline-block;
        }
      }
    }
  }
}
