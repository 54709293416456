.mi-project {
  transition: $transition;

  .mi-sectiontitle {
    margin-bottom: 30px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 22px;
  }

  .active {
    h3 {
      font-size: 20px;
      color: $color-theme;
    }
  }

  .inactive {
    h3 {
      font-size: 18px;
      color: $color-theme;
    }

    .mi-project-image {
      height: 200px;
    }
  }

  .row {
    margin-bottom: 20px;
  }

  &-image {
    display: block;
    position: relative;
    background-size: cover;
    height: 300px;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 15px;
      top: 15px;
      height: calc(100% - 30px);
      width: calc(100% - 30px);
      background: rgba(#ffffff, 0.9);
      transition: $transition;
      transform: scaleX(0);
      transform-origin: 0;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-text {
    background-color: black;
    opacity: 0.85;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px 20px;

    h3 {
      text-transform: uppercase;
      color: $color-theme;
      margin-bottom: 0;
      font-weight: 700;
      line-height: 2.14rem;

      @media #{$layout-mobile-lg} {
        line-height: 2.04rem;
      }
    }

    div.subtitle {
      font-size: 15px;
      line-height: 1.857rem;
      color: #a4acc4;

      @media #{$layout-mobile-lg} {
        line-height: 1.657rem;
      }
    }

    p {
      color: #fff;
      font-size: 12px;
      font-style: italic;
      margin-bottom: 0;
    }

    div.icons {
      svg {
        color: #33af8d;
        padding-right: 10px;
      }
    }
  }
}
